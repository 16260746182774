body {
  background-color: #F9F8F5; /* Matches the light theme on the ZipBox site */
  margin: 0;
  padding: 0px;
  overflow-x: hidden;
  min-height: 100%;
  scroll-behavior: smooth
}


.main-container {
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  background-color: #F9F8F5;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full viewport height */
  text-align: center; /* Center-align text */
}



.form-container {
  text-align: center;
  max-width: 600px;
  background-color:#D73301; 
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  align-items: center;
  margin-top: 20px;
}

.form-container h1 {
  color:white; 
}

.input-container{
  font-size: 1rem;
  padding: 10px;
  border: 1px solid #ddd;
  border-right: none; /* Removes border between input and button */
  border-radius: 5px 0 0 5px; /* Round left corners */
  outline: none;
  width: 300px;

}

.form-button {
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #333333;
  color: #ffffff;
  border: none;
  border-radius: 0 5px 5px 0; /* Round right corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #444444; /* Slightly darker on hover */
}



.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  background-color:#F9F8F5;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  text-align: center;
}

.container-header {
  position: relative; /* Allows child elements to be positioned relative to this container */

}
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* center horizontally if needed */
  margin-bottom: 20px; /* adjust spacing between title and subsequent content */
}

.logout-button {
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  border-radius: 5px 5px 5px 5px; /* Round right corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute; /* Position relative to the header */
  top: 10px; /* Distance from the top of the header */
  right: 10px; /* Distance from the right of the header */
  padding: 10px 15px;
}


.logout-button:hover {
  background-color: #c82333;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

th, td {
  text-align: left;
  padding: 12px;
  font-size: 14px;
}

th {
  background-color: #F9F8F5;
  color: #2d3748;
  font-weight: 600;
}

td {
  background-color: #F9F8F5;
  color: #4a5568;
}

tr:nth-child(even) {
  background-color: #F9F8F5;
}

tr:hover {
  background-color: #F9F8F5;
}

table .status {
  padding: 4px 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.table-container {
  overflow-x: auto;
}


.error {
  color: #e53e3e;
  font-size: 14px;
  margin-top: 10px;
}

.con-heading {
  color: blue;
  font-style: italic;
}

/* Container for the date filter */
.date-filter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

/* Style for the label */
.date-filter label {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

/* Style for the dropdown select */
.date-filter select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Focus state for the dropdown */
.date-filter select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.main-title {
  color: #dc3545;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
  .table-container {
    overflow-x: auto; /* Enable scrolling for smaller viewports */
  }

  table {
    width: 100%; /* Ensure the table scales with the container */
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  th, td {
    padding: 8px; /* Reduce padding to fit smaller screens */
  }

  .logout-button {
    font-size: 0.9rem; /* Slightly reduce logout button size on small screens */
    padding: 8px 12px;
  }
}


